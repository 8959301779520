html {
    font-size: $font-size-default;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    @media (prefers-reduced-motion: reduce) {
        animation: none;
        transition: none;
    }
}

body {
    margin: 0 auto;
    min-width: 360px;
    background-color: $body-bg;
    font-family: $font-family;
    font-size: inherit;
    line-height: 1.6;
    font-weight: 400;
    color: $body-color;
}