.btn {
    border-radius: $border-radius-small;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: inherit;
    padding: 0.75rem;

    &:active {
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
    }
}