.popup-cart {
    max-width: 516px;

    @include xl {
        max-width: 1024px;
    }
}

.popup-cart__content { 
    position: relative;
}

.product-cart__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.product-cart__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;

    @include xl {
        align-items: center;
    }
}

.product-cart__item-content {
    width: 100%;

    @include xl {
        display: flex;
    }
}

.product-cart__del {
    display: none;

    @include xl {
        display: block;
        width: 2rem;
        height: 2rem;
        background-color: transparent;
        border: 1px solid transparent;
        margin-right: 0.5rem;
        color: $color-gray-400;
        transition: color 0.2s ease;

        &:hover {
            color: $color-primary;
        }
    }
}

.product-cart__image {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    @include xl {
        margin-right: 1rem;
    }
}

.product-cart__image img {
    display: block;
    width: 6rem;
    height: auto;
    border-radius: $border-radius-small;


    @include xl {
        width: 8rem;
    }
}

.product-cart__item-header {

    @include xl {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & p {

        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: $color-secondary;

        @include xl {
            font-size: 1.125rem;
        }
    }
    
    & span {
        display: none;

        @include xl {
            display: block;
            font-size: 0.75rem;
            color: $color-gray-400;
        }
    }
}

.product-cart__item-buy {
    display: flex;
    align-items: center;
}

.product-cart__quantity {
    text-align: center;
    border-color: $color-gray-400;
}

.product-cart__price {
    margin-bottom: 0;
    margin-left: 2rem;
    color: $color-secondary;
    font-weight: 700;

    @include lg {
        width: 5rem;
        display: flex;
        justify-content: flex-end;
        font-size: 1.128rem;
    }
}

.popup-cart__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 1px solid $color-gray-50;

    & a > b {
        display: none;
    }

    @include lg {
        & a > b {
            display: inline-block;
            font-weight: 400;
        }
    }
}

.popup-cart__btn-close {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;

    & svg {
        width: 24px;
        height: 24px;
    }

    & span {
        display: none;

        @include lg {
            display: block;
            margin-left: 0.5rem;
        }
    }
}

.product-cart__total {
    display: flex;
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    color: $color-secondary;
    font-weight: 700;

    @include lg {
        justify-content: flex-end;
        font-size: 1.25rem;
    }

    & span + span {
        margin-left: 0.5rem;
    }
}

.product-cart__item-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: $border-radius-large;
    background-color: $color-gray-50;
    color: $color-gray-400;
}

.product-cart__list .product-cart__total + .product-cart__total {
    display: none;
}