.page-login__form {
    max-width: 600px; 
    padding: 1rem;
    border: 1px solid $color-secondary;
    border-radius: $border-radius-small;

    @include lg {
        padding: 2rem;
    }
}

.page-login__btn {
    display: flex;
    align-items: center;

    & .btn {
        margin-right: 2rem;
        padding: 0.75rem 2rem;
    }
}

.page-login__subtitle {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    color: $color-secondary;
}