/* Plugins */
@import '../node_modules/swiper/swiper-bundle.css';
@import '../node_modules/magnific-popup/dist/magnific-popup.css';


/* fonts */
@import "./styles/fonts.scss";

/* Include parts of Bootstrap */

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./styles/variables.scss";

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

$theme-colors: (
  "primary":    $color-primary,
  "secondary":  $color-secondary,
  "danger": $color-primary,
  "success": $green
);

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules//bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/tooltip";

/* Global */
@import "./styles/mixins.scss";
@import "./styles/global.scss";

/* Components */
@import "./styles/components/toggler-nav.scss";
@import "./styles/components/modal.scss";
@import "./styles/components/form.scss";
@import "./styles/components/btn.scss";
@import "./styles/components/preloader.scss";
@import "./styles/components/breadcrumbs.scss";
@import "./styles/components/alert.scss";
@import "./styles/components/mobile-nav.scss";

/* Template */
@import "./styles/template/page.scss";
@import "./styles/template/header";
@import "./styles/template/logo";
@import "./styles/template/main-nav";
@import "./styles/template/menu.scss";
@import "./styles/template/slideshow.scss";
@import "./styles/template/catalog.scss";
@import "./styles/template/product-layout.scss";
@import "./styles/template/popup-view.scss";
@import "./styles/template/user-bar.scss";
@import "./styles/template/cart";
@import "./styles/template/phone";
@import "./styles/template/popup-cart.scss";
@import "./styles/template/page-content.scss";
@import "./styles/template/promotion.scss";
@import "./styles/template/simple.scss";


/* Footer */
@import "./styles/footer/footer";

/* Page */

@import "./styles/page/login.scss";
@import "./styles/page/forgotten.scss";
@import "./styles/page/account.scss";


