.user-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.user-bar__action {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border: 1px solid $gray-500;
    border-radius: 1.875rem;
}

.user-bar__account-link {
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    color: $gray-100;
    margin-left: 1.875rem;
    transition: color 0.2s ease;

    @include lg {
        display: flex;
    }

    &:hover {
        color: $gray-500;
    }

    &::after {
        position: absolute;
        content: '';
        left: -1.875rem;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $gray-500;
    }
}

.user-bar__account-link svg {
    width: 2rem;
    height: 2rem;
}

.user-bar__phone {
    display: none;

    @include md {
        display: block;
    }
}

.user-bar__toggler-nav {
    margin-left: 0.5rem;

    @include lg {
        display: none;
    }
}