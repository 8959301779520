.page-account {

    & .list-unstyled li {

        margin-bottom: 0.75rem;
    }
}

.page-account .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > a {
        flex-grow: 1;
    }

    & > a + a {
        flex-grow: 0;
    }
}

.page-account .text-danger {
    font-size: 0.75rem;
    color: $color-primary;
}