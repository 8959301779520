.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.logo__image {
    display: block;
    max-width: 100%;
    height: auto;
}

.logo__creation {
    display: none;

    @include md {
        bottom: -1.75rem;
        left: 0.75rem;
        font-size: 0.75rem;
    }
}