.footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;
    background-color: #101010;
}

.footer::after {
    position: absolute;
    content: '';
    top: -170px;
    left: 0;
    right: 0;
    height: 170px;
    width: 100%;
    background: linear-gradient(to bottom,rgba(16,16,16,0) 0%,rgba(16,16,16,1) 100%);
}

.footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
        max-width: 140px;
        margin-bottom: 1rem;

        @include lg {
            max-width: 200px;
            margin-bottom: 3rem;
        }
    }

    & .list-unstyled {
        display: flex;
        color: $white;
    }

    & .list-unstyled li {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    & .list-unstyled li a {
        color: $white;
        text-decoration: none;
        transition: color 0.5s ease;
        &:hover {
            color: $color-primary;
        }
    }
}

.footer__phone {
    display: inline-block;
    text-decoration: none;
    color: $color-gray-50;
    font-weight: 500;
    font-size: 2.75rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    &:hover {
        text-decoration: none;
        color: $color-gray-50;
    }
}

.footer__social {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer__social-item {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.25rem;
    }

    & a {
        text-decoration: none;
        color: $color-gray-50;
        transition: all 0.2s ease;

        &:hover {
            color: $color-primary;
        }
    }
}