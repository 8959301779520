.header {
    position: relative;
    padding: 1rem 0;

    @include md {
        padding: 2rem 0;
    }

    @include lg {
        padding: 3rem 0;
    }
}

.header__wrapper {
    width: 100%;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include md {
        max-width: 768px;
    }

    @include lg {
        max-width: 1024px;
    }

    @include xl {
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: 1280px;
    }
}

.header__main-nav {
    display: none;

    @include lg {
        display: flex;
        padding-left: 2rem;
        padding-right: 2rem;
        flex-grow: 1;
    }
}

.header__user-bar {
    flex-grow: 1;

    @include lg {
        flex-grow: 0;
    }
}

.header__logo {
    max-width: 9rem;
    height: auto;

    @include md {
        max-width: 11rem;
    }
}

.header__toggler-nav {
    margin-right: 1rem;
    display: none;

}