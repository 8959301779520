.product-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: $border-radius-small;
    background-color: $color-gray-50;
}

.product-layout__image {
    position: relative;
    border: 0;
    border-radius: $border-radius-small $border-radius-small 0 0;
    text-decoration: none;

    & img {
        display: block;
        width: 100%;
        min-width: 100%;
        height: auto;
        border-radius: $border-radius-small $border-radius-small 0 0;
        opacity: 1;
        transition: opacity 0.2s ease;
        object-fit: contain;
    }

    @include lg  {
        &:hover img {
            opacity: 0.9;
        }
    }
}

.product-layout__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.75rem 0.5rem;

    @include xl {
        padding: 1rem;
    }
}

.product-layout__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;

    & span {
        display: block;
        margin-bottom: 0.25rem;
        line-height: 1.2;
        font-size: 0.75rem; 
        color: $color-gray-400;

        @include xl {
            font-size: 0.875rem;
        }
    }
}

.product-layout__view {
    font-weight: 500;
    text-decoration: none;
    line-height: 1.4;
    font-size: 0.85rem;
    color: $color-gray-600;

    &:hover {
        color: $color-primary;
    }

    @include xl {
        transition: color 0.2s ease;
        font-size: 1rem;
        line-height: 1.5;
    }
}

.product-layout__buy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.product-layout__price {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2;
    color: $color-secondary;
    display: flex;
    flex-direction: column;

    @include xl {
        font-size: 1.25rem;
    }
}

.product-layout__price--old {
    font-weight: 400;
    color: $color-gray-400;
    font-size: 0.75rem;
    text-decoration: line-through;
}


.product-layout__price--new {
    color: $color-primary;
}

.product-layout__button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: $white;

    & svg {
        width: 1.125rem;
        height: 1.125rem;
        min-width: 1.125rem;
        min-height: 1.125rem;
    }

    @include md {
        transition: background-color 0.2s ease, transform 0.2s ease;
        
        &:hover {
            background-color: darken($color: $color-primary, $amount: 10%);
        }

        &:active {
            transform: translateY(2px);
        }

        & svg {
            display: block;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    @include xl {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.product-layout__preloader {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.product-layout__preloader--show {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-layout__sticker {
    z-index: 2;
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius-small;
    color: $color-gray-50;
    text-decoration: none;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;

    @include xl {
        font-size: 0.875rem;
    }
}

.product-layout__sticker--new {
    background-color: #69bf12;;
}