.slideshow {
    position: relative;
    margin-bottom: 2.5rem;
    @include md {
        margin-bottom: 3.5rem;
    }
}

.slideshow__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.slideshow__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.slideshow__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.slideshow__image {
    display: block;
    width: 100%;
    min-width: 100%;
    height: auto;
    border-radius: $border-radius-small;
}


/* Slider Navigation */

.slideshow__nav {
    z-index: 2;
    position: absolute;
    top: calc(50% - 1rem);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgba($color: $white, $alpha: 90%);
    color: $color-gray-600;

    transition: all 0.2s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        width: 1rem;
        height: 1rem;
    }

    @include md {

        top: calc(50% - 1.5rem);
        width: 3rem;
        height: 3rem;

        & svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.slideshow__nav--prev {
    left: 0.5rem;

    @include md {
        left: 1rem; 
    }
} 

.slideshow__nav--next {
    right: 0.5rem;

    @include md {
        right: 1rem; 
    }
} 

.slideshow__nav:not(.slideshow__nav--disable) {
    
    @include md {
        cursor: pointer;

        &:hover {
            background-color: $color-primary;
            color: $white; 
        }
    }
    
}

.slideshow__nav--disable {
    opacity: 0.4;
}