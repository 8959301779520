/* Font */
$font-family: 'Roboto', sans-serif;
$font-size-default: 1rem;

// Colors
$color-primary: #DC2626;
$color-secondary: #1F2937; /* color-gray-800 */
$color-gray-50: #F9FAFB;
$color-gray-400: #9CA3AF;
$color-gray-600: #4B5563;

// Body
$body-bg:#101010;
$body-color: $color-gray-600;

// Border Radius
$border-radius-small: 0.5rem;
$border-radius-large: 1rem;