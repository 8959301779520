.page-content__title {
    display: block;
    position: relative;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 1.2;
    color: $color-secondary; 

    @include md {
        font-size: 1.75rem;
    }
}

.page-content__empty {
    display: inline-block;
    border-radius: $border-radius-small;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    background-color: $color-gray-50;
    color: $color-gray-400;
}

.page-content__buttons-empty {
    display: flex;
    justify-content: flex-end;
}

.page-content__description {

    & > img  {
        display: block;
        width: 100%;
        max-width: 800px;
        border-radius: $border-radius-small;
        margin-bottom: 1rem;
    }
}