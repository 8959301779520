.menu {
    position: relative;
    background-color: $white;
    margin-top: -1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include md {
        margin-bottom: 1rem;
    }
}

.menu_list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.menu__item {
    width: auto;
}

.menu__link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: $border-radius-small;
    background-color: $color-secondary;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: $color-gray-50;

    &:hover {
        color: $white;
    }

    @include md {
        transition: all 0.2s ease;
        &:hover {
            background-color: $color-primary;
            color: $white;
        }

        &:active {
            opacity: 0.8;
        }
    }

    

    @include md {
        font-size: 1.125rem;
    }
}

.menu__link-name {
    white-space: nowrap;
}

.menu__link-image {
    display: block;
    width: 2rem;
    height: auto;
    margin-right: 0.5rem;

    @include md {
        width: 2.625rem;
    }
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px)
    }

    100% {
        transform: translateY(0)
    }
}

.menu--fixed {
    z-index: 99;
    position: sticky;
    left: 0;
    top: 0;
    animation: smoothScroll .5s forwards;
}