.catalog {
    position: relative;
}

.catalog__row {
    margin-bottom: 2.5rem;

    @include md {
        margin-bottom: 3.5rem;
    }
}

.catalog__header {
    margin-bottom: 1rem;

    @include md {
        margin-bottom: 1.5rem;
    }
}

.catalog__title {
    display: block;
    padding-left: 1.25rem;
    position: relative;
    font-size: 1.5rem;
    line-height: 1.2;
    color: $color-secondary;

    @include md {
        font-size: 1.75rem;
    }

    &::before {
        position: absolute;
        content: '';
        left: 0;
        top: calc(50% - 0.25rem);
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $color-primary;
    }
}


.catalog__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    align-items: stretch;

    @include sm {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include lg {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }

    @include xl {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
    }
}

.catalog__empty {
    display: inline-block;
    border-radius: $border-radius-small;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    background-color: $color-gray-50;
    color: $color-gray-400;
}
