.page {
    position: relative;
    overflow-x: hidden;
}

.page__fixed-bg {
    position: fixed;
    width: 100%;
    height: 46.25rem;
    background-image: url(./../image/image-page-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.6);   
    }

    &::after {
        z-index: 1;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 350px;
        background: linear-gradient(to bottom,rgba(16,16,16,0) 0%,rgba(16,16,16,1) 100%);
    }
}

.page__main {
    z-index: 1;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;

    @include md {
        max-width: 768px;
        padding-left: 0;
        padding-right: 0;
    }

    @include lg {
        max-width: 1024px;
    }

    @include xl {
        max-width: 1280px;
    }
}

.page__main-inner {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: $border-radius-large;

    @include xl {
        padding: 2rem;
    }
}