.toggler-nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background-color: transparent;
    color: $white;
}

.toggler-nav > svg {
    width: 2rem;
    height: 2rem;
}