.mobile-nav__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.mobile-nav__item a {
    display: inline-block;
    text-decoration: none;
    color: $color-secondary;
    font-size: 1.125rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    border-bottom: 1px dashed $color-primary;
}

.mobile-nav__phone {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.125rem;
    margin-top: 0.5rem;
    color: $color-gray-400;
    font-weight: 500;

    &:hover {
        color: $color-gray-400;
    }

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.25rem;
    }

    & span {
        color: $color-secondary;
    }
}