.promotion {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    align-items: stretch;

    @include sm {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include lg {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }

    @include xl {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
    }
}

.promotion__item {

    & .image {
        margin-bottom: 1rem;
    }

    & .image a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .image img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: $border-radius-small;
    }

    & .caption a {
        font-weight: 500;
        text-decoration: none;
        line-height: 1.4;
        font-size: 0.85rem;
        color: $color-gray-600;

        &:hover {
            color: $color-primary;
        }

        @include xl {
            transition: color 0.2s ease;
            font-size: 1rem;
            line-height: 1.5;
        }
    }
}