.simple__cart {
    border: 1px solid $color-secondary;
    border-radius: $border-radius-small;
    background-color: $color-secondary;
}

.simple__cart-heading {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $color-gray-400;
    color: $color-gray-50;
}

.simple__cart .simplecheckout-cart  {
    border: 1px solid transparent;
}

.simple__simplecheckout-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include lg {
        flex-wrap: nowrap;
    }

    & .name {
        width: 100%;
        margin-bottom: 1rem;

        @include lg {
            width: auto;
            flex-grow: 1;
            margin-bottom: 0;
        }
    }

    & .name p {
        margin-bottom: 0;
        color: $color-gray-50;
    }

    & .total {
        font-weight: 500;
        color: $color-gray-50;
    }

}

.simple__quantity {
    display: flex;
    margin-right: 1rem;

    & .form-control {
        border: 1px solid $color-secondary;
        background-color: lighten($color: $color-secondary, $amount: 10%);
        text-align: center;
        color: $color-gray-50;
        width: 4rem !important;
    }

    & .btn {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        color: $color-gray-400;

        & svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.simple__cart .simplecheckout-cart-total {
    background-color: $color-gray-600;
    border: 1px solid transparent;
    margin-bottom: 1px;

    & span {
        font-size: 0.875rem;
        color: $color-gray-50;
        font-weight: 400;
    }

    & span + span {
        font-weight: 500;
        font-size: 1rem;
    }


}
/* cart end */
/* checkout heading */
.simple-content .checkout-heading.panel-heading {
    border-radius: $border-radius-small;
    background-color: $color-secondary;
    color: $color-gray-50;
    padding: 1rem 1.5rem;
    line-height: 1.2;
}


.row-customer_register {
    display: flex;
    align-items: center;
    margin-top: 0.875rem;

    & .control-label {
        font-weight: 500;
        font-size: 0.875rem;
        color: $color-secondary;
    }

    & .wda__form-group {
        margin-left: 1rem;
    }
}

.wda__radio .radio,
.radio__remade {

    & input {
        display: none;
    }

    & label span {
        position: relative;
        padding-left: 1.75rem;
        color: $color-gray-600;
        cursor: pointer;
        line-height: 1;

        &::before {
            position: absolute;
            content: '';
            top: calc(50% - 0.75rem);
            left: 0;
            width: 1.25rem;
            height: 1.25rem;
            border: 0.125rem solid $color-gray-600;
            border-radius: 4px;
            transition: all 0.2s ease;
        }
    }

    & label input:checked + span::before {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: $color-primary;
        font-weight: 700;
        content: '✓';
        border-color: $color-primary;
    }
}

.radio__remade {
    margin-bottom: 0.5rem;
}

#simplecheckout_customer .form-horizontal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#simplecheckout_customer .form-group:not(.row-customer_register) {
    & label {
        color: $color-gray-600;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
    }
}

#simplecheckout_customer .form-group.required {
    
    & label {
        position: relative;
        padding-left: 0.5rem;

        &::before {
            position: absolute;
            content: '*';
            left: 0;
            top: 0;
            color: $color-primary;
        }
    }
}

.simple__header {
    font-size: 1.125rem;
    color: $color-secondary;
    font-weight: 500;
}

.row-customer_sticks_ordinary,
.row-customer_sticks_educational {
    width: calc(50% - 1rem)

}

.row-customer_birthday label.control-label {
    display: none !important;
}

#simplecheckout_shipping {
    margin-bottom: 0;
}
#simplecheckout_shipping .simplecheckout-block-content {
    margin-top: 1rem;
}

#simplecheckout_shipping_address .form-group {
    & label {
        color: $color-gray-600;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
    }
}

#simplecheckout_shipping_address .form-group.required {
    
    & label {
        position: relative;
        padding-left: 0.5rem;

        &::before {
            position: absolute;
            content: '*';
            left: 0;
            top: 0;
            color: $color-primary;
        }
    }
}

#simplecheckout_shipping_address .form-horizontal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-group__desckr {
    padding: 1rem;
    background-color: $color-gray-50;
    color: $color-gray-400;
    font-size: 0.875rem;
    border-radius: $border-radius-small;
}

.row-shipping_address_street {
    width: 100%;

    @include xl {
        width: 50%;
    }
}

.row-shipping_address_house {
    width: 100%;

    @include xl {
        width: calc(25% - 1rem);
    }
}

.row-shipping_address_flat {
    width: 100%;

    @include xl {
        width: calc(25% - 1rem);
    }
}

#simplecheckout_payment {
    margin-bottom: 0;

    & .simplecheckout-block-content {
        margin-top: 1rem;
    }
}

.simplecheckout-error-text {
    font-size: 0.74rem;
    color: $color-primary;
}

.simplecheckout-right-column {

    @include xl {
        top: 20px;
        position: sticky;
    }
}

.checkout-heading-button a {
    color: $color-gray-50;
    text-decoration: none;
    border-bottom: 1px dashed $color-primary;
}