.page-forgotten__subtitle {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    color: $color-gray-400;
    max-width: 600px;
}

.page-forgotten__form {
    display: flex;
    flex-direction: column;
    max-width: 600px;

    & .buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    & .buttons .btn {
        padding: 0.75rem 2rem;
    }
}