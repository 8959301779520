.main-nav a {
    position: relative;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1rem;
    color: $gray-100;
    text-decoration: none;
    transition: opacity 0.2s ease;

    &::before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: -0.125rem;
        height: 2px;
        width: 100%;
        background-color: transparent;
        transform: translateY(10px);
        transition: all 0.2s ease;
    }

    &:hover::before {
        background-color: $color-primary;
        transform: translateY(0);
    }
}