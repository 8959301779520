.popup-view {
    max-width: 516px;

    @include xl {
        max-width: 1024px;
    }
}

.popup-view__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include xl {
        flex-direction: row;
    }
}

.popup-view__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    border-radius: $border-radius-small;

    & img {
        width: 100%;
        height: auto;
        border-radius: $border-radius-small;
    }

    @include xl {
        max-width: 26rem;
        min-width: 26rem;
        height: auto;
        margin-bottom: 0;
        margin-right: 2rem;
    }
}

.popup-view__content {

    @include xl {
        flex-grow: 1;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.popup-view__name {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.25rem;
    color: $color-secondary;

    @include lg {
        font-size: 1.5rem;
    }
}

.popup-view__include {
    display: block;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: $color-gray-400;
}

.popup-view__description {
    color: $color-gray-600;
    font-weight: 400;
    margin-bottom: 1rem;
}

.popup-view__price {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
    color: $color-secondary;
    margin-bottom: 1.2rem;
    margin-top: 2rem;
}

.popup-view__price--old {
    text-decoration: line-through;
    font-weight: 400;
    font-size: 0.875rem;
    color: $color-gray-400;
}

.popup-view__price--new {
    color: $color-primary;
}

.popup-view__buy {
    display: flex;

    & .input-group {
        margin-right: 2rem;
    }
}

.popup-view__add-cart {
    padding: 0.75rem 1.5rem;
}
