.cart-button {
    position: relative;
    padding: 0;
    margin-right: 1rem;
    color: $gray-100;

    &:hover {
        color: $gray-500;
    }

    &::after {
        position: absolute;
        content: '';
        right: -1rem;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $white;
        opacity: 0.4;
    }

}

.cart-button svg {
    width: 2rem;
    height: 2rem;
}

.cart-button span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.25rem;
    right: -0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.75rem;
    background-color: $red-500;
    border-radius: 50%;
    color: $white;
    transition: color 0.25s ease;
}