.breadcrumb {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1rem 0;

    display: flex;
    flex-wrap: wrap;
}

.breadcrumb > li a {
    padding-right: 1.5rem;
    text-decoration: none;
    color: $color-gray-400;
    font-size: 0.875rem;

    &:hover {
        color: $color-secondary;
    }
}
.breadcrumb > li:not(:last-child) {
    position: relative;
}

.breadcrumb > li:not(:last-child)::before {
    position: absolute;
    content: '→';
    top: 50%;
    right: 0.25rem;
    color: $color-gray-400;
    line-height: 1.2;
    font-size: 0.875rem;
    transform: translateY(-50%);
}