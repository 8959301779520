.form-control {
    border-radius: 0.5rem;
    border: 1px solid $color-gray-400;
    padding: 0.75rem;
    font-size: 1rem;
    line-height: 1.2;
    background-color: $color-gray-50;
    color: $color-secondary;
}

.form-control:focus {
    border: 1px solid $color-gray-400;
    background-color: $white;
    box-shadow: none;
}

.input__quantity {
    max-width: 7rem;
}

.input__quantity .form-control {
    text-align: center;
}

.input__quantity .form-control:focus {
    border: 1px solid $color-gray-50;
}

.input__quantity-field {
    border: 1px solid $color-gray-50;
}

.input__quantity-btn {
    background-color: $color-gray-50;
    color: $color-gray-600;
    font-weight: 700;

    &:hover {
        background-color: darken($color-gray-50, $amount: 10%);
    }

    &:focus {
        box-shadow: none;
    }
}

.input__quantity-btn--minus {
    border-radius: $border-radius-small 0 0 $border-radius-small;
}

.input__quantity-btn--plus {
    border-radius: 0 $border-radius-small $border-radius-small 0;
}

/* Form group */

.form-group {
    width: 100%;
    margin-bottom: 1rem;
}

.control-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.2;
    color: $color-gray-600;
}